angular
  .module('app')
  .component('settings', {
    templateUrl: 'app/modules/app/settings/settings.html',
    controller: SettingsCtrl
  });

function SettingsCtrl($log, WebsocketService) {
  WebsocketService.setOnReceiveSensor(null);
  WebsocketService.setOnReceiveAlarm(null);
}
