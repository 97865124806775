(function () {
  'use strict';

  angular.module('app')
    .config(HistoryRoutes);

  /**
   * @ngInject
   * @param $stateProvider
   * @constructor
   */
  function HistoryRoutes($stateProvider) {
    $stateProvider
      .state('app.station.history', {
        url: '/history',
        component: 'history'
      });
  }
})();
