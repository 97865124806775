angular
  .module('app')
  .component('total', {
    templateUrl: 'app/modules/app/station/total/total.html',
    controller: TotalCtrl
  });

function TotalCtrl($scope, $rootScope, $state, $stateParams, $log, $interval, SensorService, WebsocketService) {
  var vm = this;

  vm.stationId = $stateParams.stationId;
  vm.currentReadings = [];

  function sortByNameWithTwoNumbers(a, b) {
    var namea = a.sensorCode;
    var nameb = b.sensorCode;
    var lettersa = namea.replace(/[^\D]+/g, '');
    var lettersb = nameb.replace(/[^\D]+/g, '');
    var num1a = Number(namea.replace(/^[\D]+/, '').replace(/[\D]+[^\D]+(_T)?$/, ''));
    var num1b = Number(nameb.replace(/^[\D]+/, '').replace(/[\D]+[^\D]+(_T)?$/, ''));
    var num2a = Number(namea.replace(/^[\D]+[^\D]+[\D]+/, '').replace(/(_T)?$/, ''));
    var num2b = Number(nameb.replace(/^[\D]+[^\D]+[\D]+/, '').replace(/(_T)?$/, ''));

    if (lettersa !== lettersb) {
      return lettersa > lettersb ? 1 : (lettersa < lettersb ? -1 : 0);
    }
    if (num1a !== num1b) {
      return num1a > num1b ? 1 : (num1a < num1b ? -1 : 0);
    }
    return num2a > num2b ? 1 : (num2a < num2b ? -1 : 0);
  }

  vm.initReadings = function () {
    var list = [];
    angular.forEach($rootScope.stationDetails.sensors, function (sensor) {
      list.push({
        id: sensor.id,
        sensorCode: sensor.code,
        sensorName: sensor.name,
        type: sensor.sensorType,
        section: sensor.section
      });
    });
    vm.currentReadings = list.sort(sortByNameWithTwoNumbers);
    // vm.currentReadings = _.sortBy(list, 'sensorName', function(num){
    //   return num;
    // });
  };

  vm.initReadings();

  vm.refreshReadings = function (result) {
    angular.forEach(result, function (row) {
      angular.forEach(vm.currentReadings, function (displayed) {
        if (displayed.sensorCode === row.sensorCode) {
          displayed.value = row.value;
          displayed.valueMin = row.valueMin;
          displayed.valueMax = row.valueMax;
          displayed.valueMean = row.valueMean;
        }
      });
    });
    $scope.$apply();
  };

  WebsocketService.setOnReceiveSensor(vm.refreshReadings);
  WebsocketService.setOnReceiveAlarm(null);

  vm.getTotalLabel = function (value) {
    if (angular.isUndefined(value)) {
      return '-';
    }
    return value.replace(/ \(stan\)/, '');
  };

}
