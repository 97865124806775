angular
  .module('app')
  .component('users', {
    templateUrl: 'app/modules/app/settings/users/users.html',
    controller: UsersCtrl
  });

function UsersCtrl($state, $stateParams, $log, UserService, StationService, $filter, $timeout) {
  var vm = this;

  vm.newItem = null;

  vm.usersList = [];
  vm.stationList = [];
  loadData();

  StationService.schemaAll().then(function (response) {
    vm.stationList = response;
  });

  vm.roleList = [
    {id: 1, name: 'Administrator'},
    {id: 2, name: 'Użytkownik'}
  ];

  vm.getRoleList = function (){
    var availableRoles = angular.copy(vm.roleList);
    if (UserService.userData() && UserService.userData().role.id === 3) {
      availableRoles.push({id: 3, name: 'Programista'});
    }
    return availableRoles;
  };

  vm.editUser = function (user) {
    user.edit = true;
  };

  vm.saveUser = function (user) {
    user.edit = 0;
    user.saving = true;
    return UserService.saveUser(user).then(function (response) {
      angular.copy(response, user);
      $timeout(function () {
        user.saving = false;
      }, 1000);
      return user;
    });
  };

  vm.addUser = function () {
    vm.newItem = {};
  };

  vm.saveNewUser = function () {
    var userData = angular.copy(vm.newItem);
    vm.saveUser(userData).then(function (user) {
      vm.usersList.push(user);
    });
    vm.clearNewUser();
  };

  vm.clearNewUser = function () {
    vm.newItem = null;
  };

  vm.sameUser = function (user) {
    return UserService.userData().id === user.id;
  };

  vm.availableNewStations = function (user) {
    var hasAllStationsPerm = UserService.hasPerm('ALL_STATIONS');
    var allStations;
    if (hasAllStationsPerm) {
      allStations = vm.stationList;
    } else {
      allStations = UserService.userData().stations;
    }
    var newStations = _.filter(allStations, function(station, index) {
      var stationExists = _.findWhere(user.stations, {id: station.id});
      console.log(stationExists);
      return !stationExists;
    })
    return newStations;
  };

  vm.addStation = function (user) {
    if (user.addStation != null) {
      var existing = _.findWhere(user.stations, {id: user.addStation.id});
      if(!existing) {
        var index = user.stations.indexOf(user.addStation);
        user.stations.push(user.addStation);
        user.addStation = null;
      }
    }
  };

  vm.removeStation = function (user, station) {
    var index = user.stations.indexOf(station);
    user.stations.splice(index, 1);
  };

  function loadData() {
    UserService.users().then(function (response) {
      vm.usersList = response;
    });
  }
}
