angular
  .module('app')
  .component('app', {
    templateUrl: 'app/modules/app/app.html',
    controller: AppCtrl
  });

function AppCtrl($state, $log, $rootScope, UserService) {
  var vm = this;

  vm.appReady = $rootScope.appReady;

  vm.getName = function () {
    if (UserService.userData() === null) {
      return 'Profil';
    }
    return UserService.userData().name;
  };

  vm.getStations = function () {
    if (UserService.userData() === null) {
      return [];
    }
    return UserService.userData().stations;
  };

  vm.hasPerm = function (perm) {
    return UserService.hasPerm(perm);
  };

  vm.logout = function () {
    UserService.logout();
    $state.go('login');
  };

  vm.isStationDetailsLoaded = function () {
    return Boolean($rootScope.stationDetails);
  };

  vm.getStationName = function () {
    if (!$rootScope.stationDetails) {
      return '';
    }
    return $rootScope.stationDetails.name;
  };

  vm.jatechSiteVisible = false;
  vm.showJatechSite = function () {
    vm.jatechSiteVisible = true;
  }
}
