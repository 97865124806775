angular
  .module('app')
  .component('profile', {
    templateUrl: 'app/modules/app/settings/profile/profile.html',
    controller: ProfileCtrl
  });

function ProfileCtrl($state, $stateParams, $log, $timeout, UserService) {
  var vm = this;

  vm.user = {};
  vm.savingMsg = null;

  UserService.authenticated().then(function (response) {
    vm.user = angular.copy(response);
  });

  vm.saveProfile = function () {
    vm.savingMsg = 'Zapisywanie...';
    UserService.saveProfile(vm.user).then(function (response) {
      vm.user = response;
      UserService.setUser(angular.copy(response));
      vm.savingMsg = 'Zapisano';
      vm.clearSavingMsg();
    }, function (response) {
      vm.savingMsg = 'Bład zapisu';
      vm.clearSavingMsg();
    });
  };

  vm.clearSavingMsg = function () {
    $timeout(function () {
      vm.savingMsg = null;
    }, 1000);
  };

}
