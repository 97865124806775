angular
  .module('app')
  .component('current', {
    templateUrl: 'app/modules/app/station/current/current.html',
    controller: CurrentCtrl
  });

function CurrentCtrl($scope, $rootScope, $state, $stateParams, $log, $interval, SensorService, WebsocketService, AlarmService) {
  var vm = this;

  vm.stationId = $stateParams.stationId;
  vm.currentReadings = [];

  function sortByNameWithTwoNumbers(a, b) {
    var namea = a.sensorCode;
    var nameb = b.sensorCode;
    var lettersa = namea.replace(/[^\D]+/g, '');
    var lettersb = nameb.replace(/[^\D]+/g, '');
    var num1a = Number(namea.replace(/^[\D]+/, '').replace(/[\D]+[^\D]+(_T)?$/, ''));
    var num1b = Number(nameb.replace(/^[\D]+/, '').replace(/[\D]+[^\D]+(_T)?$/, ''));
    var num2a = Number(namea.replace(/^[\D]+[^\D]+[\D]+/, '').replace(/(_T)?$/, ''));
    var num2b = Number(nameb.replace(/^[\D]+[^\D]+[\D]+/, '').replace(/(_T)?$/, ''));

    if (lettersa !== lettersb) {
      return lettersa > lettersb ? 1 : (lettersa < lettersb ? -1 : 0);
    }
    if (num1a !== num1b) {
      return num1a > num1b ? 1 : (num1a < num1b ? -1 : 0);
    }
    return num2a > num2b ? 1 : (num2a < num2b ? -1 : 0);
  }

  vm.initReadings = function () {
    var list = [];
    angular.forEach($rootScope.stationDetails.sensors, function (sensor) {
      list.push({
        id: sensor.id,
        sensorCode: sensor.code,
        sensorName: sensor.name,
        type: sensor.sensorType,
        section: sensor.section
      });
    });
    vm.currentReadings = list.sort(sortByNameWithTwoNumbers);
  };

  vm.initReadings();

  vm.refreshReadings = function (result) {
    angular.forEach(result, function (row) {
      angular.forEach(vm.currentReadings, function (displayed) {
        if (displayed.sensorCode === row.sensorCode) {
          displayed.value = row.value;
          displayed.valueMin = row.valueMin;
          displayed.valueMax = row.valueMax;
          displayed.valueMean = row.valueMean;
        }
      });
    });
    $scope.$apply();
  };

  vm.refreshAlarms = function (result) {
    vm.currentAlarms = result;
  };

  WebsocketService.setOnReceiveSensor(vm.refreshReadings);
  WebsocketService.setOnReceiveAlarm(vm.refreshAlarms);

  vm.getStatusClass = function (value) {
    if (angular.isUndefined(undefined)) {
      return '';
    }
    if (value === 1) {
      return 'badge-success';
    } else if (value === 2) {
      return 'badge-warning';
    } else if (value === 3) {
      return 'badge-danger';
    }
    return 'badge-secondary';
  };

  vm.getStatusLabel = function (value) {
    if (angular.isUndefined(value)) {
      return '-';
    }
    if (value === 1) {
      return 'Praca';
    } else if (value === 2) {
      return 'Regeneracja';
    } else if (value === 3) {
      return 'Alarm';
    }
    return 'Spoczynek';
  };

  vm.getStatusMeanLabel = function (value) {
    if (angular.isUndefined(value)) {
      return '-';
    }
    return (value * 100).toFixed(0) + '%';
  };

  vm.hasSection = function (sectionCode) {
    return _.some(vm.currentReadings, {section: sectionCode});
  };

  vm.areAlarmsEnabled = function () {
    return $rootScope.stationDetails.alarmsEnabled;
  };

  vm.formatAlarmDate = function (date) {
    if (!date) {
      return "";
    }
    return date.split('T')[0];
  };

  vm.formatAlarmTime = function (date) {
    if (!date) {
      return "";
    }
    return date.split('T')[1];
  };

  vm.resolveAlarm = function (alarm) {
    if (confirm('Czy potwierdzasz, że problem został rozwiązany?')) {
      AlarmService.resolveAlarm(alarm).then(function () {
        angular.forEach(vm.currentAlarms, function (item) {
          if (item.code === alarm.code) {
            item.triggered = false;
          }
        });
        alert("Zapisano, że problem został rozwiązany");
        $rootScope.$broadcast('refresh-station');
      });
    }
  };
}
