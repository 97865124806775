(function () {
  'use strict';

  angular.module('app')
    .config(SchemaRoutes);

  /**
   * @ngInject
   * @param $stateProvider
   * @constructor
   */
  function SchemaRoutes($stateProvider) {
    $stateProvider
      .state('app.station.schema', {
        url: '/schema/{schemaId}',
        component: 'schema',
        params: {
          debug: false
        }
      })
      .state('app.station.schemadebug', {
        url: '/schema/{schemaId}/debug',
        component: 'schema',
        params: {
          debug: true
        }
      });

  }
})();
