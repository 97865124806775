(function () {
  'use strict';

  angular.module('app').service('SensorService', SensorService);

  /* @ngInject */
  function SensorService($log, HttpService) {
    var vm = this;

    vm.currentReadings = function (stationId) {
      return HttpService.postJson('/api/sensorData/current', {stationId: stationId});
    };

    vm.history = function (criteria) {
      return HttpService.postJson('/api/sensorData/history', criteria);
    };

    vm.export = function (criteria) {
      return HttpService.postBinary('/api/sensorData/export', criteria);
    };

    vm.schema = function (schemaId) {
      return HttpService.getJson('/api/sensor/loadSchema/' + schemaId);
    };

    vm.saveSensorConfig = function (criteria) {
      return HttpService.postJson('/api/sensor/save', criteria);
    };

  }
})();
