(function () {
  'use strict';

  angular.module('app')
    .controller('MainController', MainController);

  /**
   * @ngInject
   */
  function MainController($log, $state, UserService) {
    var vm = this;
    vm.appReady = false;
    vm.user = null;

    (function () {
      UserService.authenticated().then(function (response) {
        if (response === null) {
          $state.go('login');
          vm.appReady = true;
        } else {
          vm.user = response;
          vm.appReady = true;
        }
      });
    })();

    vm.logout = function () {
      $state.go('login');
    };
  }
})();
