angular
  .module('app')
  .component('schema', {
    templateUrl: 'app/modules/app/station/schema/schema.html',
    controller: SchemaCtrl
  });

function SchemaCtrl($state, $stateParams, $log, $document, $interval, $scope, $rootScope, $filter,
                    WebsocketService, SensorService, UserService, AlarmService) {
  var vm = this;

  vm.debug = $stateParams.debug;
  vm.stationId = $stateParams.stationId;
  vm.schemaId = Number($stateParams.schemaId);

  vm.schemaTab = _.findWhere($rootScope.stationDetails.tabs, {id: vm.schemaId});

  if (!vm.schemaTab) {
    if ($rootScope.stationDetails.tabs.length > 0) {
      $state.go($state.current.name, {stationId: vm.stationId, schemaId: $rootScope.stationDetails.tabs[0].id});
    } else {
      $state.go('app.station.current', {stationId: vm.stationId});
    }
  }

  vm.configPointsMap = [];
  vm.canvas = $document[0].getElementById('canvas');
  vm.context = canvas.getContext('2d');
  vm.canvas.width = 1920;
  vm.canvas.height = 1080;

  vm.context.globalAlpha = 1.0;

  vm.currentReadings = [];

  vm.image = new Image();
  vm.image.onload = imageReady;
  if (vm.schemaTab) {
    vm.image.src = vm.schemaTab.imageUrl;
  }

  vm.valuePointsMap = {};

  function imageReady() {
    angular.forEach(vm.schemaTab.schemaSensors, function (item) {
      //FIXME przenieść na wczytywanie razem z odczytami
      var minLabel, maxLabel, ppozLabel;
      var sensorConfig = _.findWhere($rootScope.stationDetails.sensors, {code: item.sensorCode});
      if (sensorConfig && sensorConfig.minValue) {
        minLabel = 'min ' + Number(sensorConfig.minValue).toFixed(2);
        if (sensorConfig.minTolerance) {
          minLabel += ' \xB1 ' + Number(sensorConfig.minTolerance).toFixed(2);
        }
      }

      if (sensorConfig && sensorConfig.maxValue) {
        maxLabel = 'max ' + Number(sensorConfig.maxValue).toFixed(2);
        if (sensorConfig.maxTolerance) {
          maxLabel += ' \xB1 ' + Number(sensorConfig.maxTolerance).toFixed(2);
        }
      }

      if (sensorConfig && sensorConfig.ppozValue) {
        ppozLabel = 'ppoz ' + Number(sensorConfig.ppozValue).toFixed(2);
        if (sensorConfig.ppozTolerance) {
          ppozLabel += ' \xB1 ' + Number(sensorConfig.ppozTolerance).toFixed(2);
        }
      }

      vm.configPointsMap.push({
        code: item.sensorCode,
        xpos: item.positionX,
        ypos: item.positionY,
        type: item.displayType,
        scale: 10 * item.scale,
        maxLabel: maxLabel,
        minLabel: minLabel,
        ppozLabel: ppozLabel,
        barMax: item.barMax,
      });
    });


    vm.loadPoints();
  }

  vm.refreshValues = function (result) {
    vm.currentReadings = result;
    angular.forEach(result, function (item) {
      vm.valuePointsMap[item.sensorCode] = item;
    });
    vm.loadPoints();
  };

  vm.refreshAlarms = function (result) {
    vm.currentAlarms = result;
  };

  WebsocketService.setOnReceiveSensor(vm.refreshValues);
  WebsocketService.setOnReceiveAlarm(vm.refreshAlarms);

  vm.debugPoint = function (x, y, text) {
    if (vm.debug) {
      vm.context.beginPath();
      vm.context.fillStyle = 'red';
      vm.context.font = 12 + 'px Arial Bold';
      vm.context.textAlign = 'center';
      vm.context.textBaseline = 'middle';
      vm.context.fillText(text, x, y);
    }
  };

  vm.addPoint = function (x, y, size, fill, name) {
    vm.context.beginPath();
    vm.context.arc(x, y, size, 0, 2 * Math.PI, false);
    vm.context.fillStyle = fill;
    vm.context.fill();
    vm.context.lineWidth = 1;
    vm.context.strokeStyle = '#666666';
    vm.context.stroke();
    vm.debugPoint(x, y + 17, name);
  };

  vm.addInput = function (xpos, ypos, size, fill, text, maxChar, name, fractionDigits) {

    var width = 0.1 * maxChar * 6 * size;
    var height = 0.1 * 16 * size;

    var x = xpos - (width / 2);
    var y = ypos - (height / 2);
    var value = text;

    if (!isNaN(text) && isFinite(text)) {
      value = Number(text).toFixed(fractionDigits);
    }

    if (value.length > maxChar) {
      size = Math.floor(size / 1.5);
    }

    vm.context.beginPath();
    vm.context.fillStyle = '#666666';
    vm.context.fillRect(x - 1, y - 1, width + 2, height + 2);

    vm.context.beginPath();
    vm.context.fillStyle = fill;
    vm.context.fillRect(x, y, width, height);

    vm.context.beginPath();
    vm.context.fillStyle = '#000000';
    vm.context.font = size + 1 + 'px Arial';
    vm.context.textAlign = 'center';
    vm.context.textBaseline = 'middle';
    vm.context.fillText(value, (width / 2) + x, (height / 2) + y);
    vm.debugPoint(xpos, ypos, name);

  };

  vm.addBar = function (xpos, ypos, size, fill, value, max, name) {

    var width = 0.1 * 6 * size;
    var height = 0.1 * 18 * size;
    var x = xpos - (width / 2);
    var y = ypos - height;
    var defaultColor = '#FFFFFF';
    var cnt = value;
    for (var i = 0; i < max; i++) {
      var newY = y - ((i * height) + 1);
      vm.context.beginPath();
      vm.context.fillStyle = '#666666';
      vm.context.fillRect(x - 1, newY - 1, width + 2, height + 2);

      vm.context.beginPath();
      vm.context.fillStyle = defaultColor;
      vm.context.fillRect(x, newY, width, height);

      if (cnt > 0) {
        vm.context.beginPath();
        vm.context.fillStyle = '#0080ff';
        if (cnt > 0.99999) {
          vm.context.fillRect(x, newY, width, height);
        } else {
          vm.context.fillRect(x, newY + height - (height * cnt), width, (height * cnt));
        }
      }

      vm.context.beginPath();
      vm.context.fillStyle = '#666666';
      vm.context.font = size + 'px Arial';
      vm.context.textAlign = 'left';
      vm.context.textBaseline = 'middle';
      vm.context.fillText(i + 1, width + x + (width * 0.4), newY);

      cnt -= 1;
    }
    vm.debugPoint(xpos, ypos + 17, name);
  };

  vm.drawImage = function () {
    vm.canvas.width = vm.image.naturalWidth;
    vm.canvas.height = vm.image.naturalHeight;
    vm.context.drawImage(vm.image, 0, 0);
  };

  function translateStatus(databaseSensorValue, colors, defaultColor) {
    if (databaseSensorValue.alert) {
      return colors.alert;
    } else if (databaseSensorValue.warning) {
      return colors.warning;
    } else if (databaseSensorValue.active) {
      return colors.active;
    }
    return defaultColor;
  }

  vm.loadPoints = function () {
    vm.context.clearRect(0, 0, vm.canvas.width, vm.canvas.height);
    vm.drawImage();
    vm.configPointsMap.forEach(function (point, code) {
      var scale = _.has(point, 'scale') ? point.scale : 10;
      if (point.type === 'CIRCLE') {
        var colorCode = '#d9d9d9';
        var colorStatus = _.has(point, 'colorStatus') ? point.colorStatus : {
          alert: 'red',
          warning: 'orange',
          active: 'green'
        };
        if (_.has(vm.valuePointsMap, point.code)) {
          vm.addPoint(point.xpos, point.ypos, scale, translateStatus(vm.valuePointsMap[point.code], colorStatus, colorCode), point.code);
        } else {
          vm.addPoint(point.xpos, point.ypos, scale, colorCode, point.code);
        }
      }
      if (point.type === 'INPUT') {
        var colorCode = _.has(point, 'color') ? point.color : 'white';
        var maxChar = 7;
        if (_.has(vm.valuePointsMap, point.code)) {
          vm.addInput(point.xpos, point.ypos, scale, colorCode, vm.valuePointsMap[point.code].value, maxChar, point.code, 2);
        } else {
          vm.addInput(point.xpos, point.ypos, scale, colorCode, '-', maxChar, point.code, 2);
        }
      }
      if (point.type === 'INPUT_BIG') {
        var colorCode = _.has(point, 'color') ? point.color : 'white';
        var maxChar = 7;
        if (_.has(vm.valuePointsMap, point.code)) {
          vm.addInput(point.xpos, point.ypos, scale, colorCode, vm.valuePointsMap[point.code].value, maxChar, point.code, 0);
        } else {
          vm.addInput(point.xpos, point.ypos, scale, colorCode, '-', maxChar, point.code, 0);
        }
      }
      if (point.type === 'MINMAX') {
        var colorCode = 'white';
        var maxChar = 15;
        var height = 0.1 * 15 * scale;
        if (point.maxLabel) {
          vm.addInput(point.xpos, point.ypos - height / 2, scale, colorCode, point.maxLabel, maxChar, point.code, 2);
        }
        if (point.minLabel) {
          vm.addInput(point.xpos, point.ypos + height / 2, scale, colorCode, point.minLabel, maxChar, point.code, 2);
        }
        if (point.ppozLabel) {
          vm.addInput(point.xpos, point.ypos + height / 2 + height, scale, colorCode, point.ppozLabel, maxChar, point.code, 2);
        }
      }
      if (point.type === 'BAR') {
        var colorCode = '#ff0000';
        var max = point.barMax || 6;
        if (_.has(vm.valuePointsMap, point.code)) {
          vm.addBar(point.xpos, point.ypos, scale, colorCode, vm.valuePointsMap[point.code].value, max, point.code);
        } else {
          vm.addBar(point.xpos, point.ypos, scale, colorCode, 0, max, point.code);
        }
      }
    });
  };

  vm.resolveAlarm = function (alarm) {
    if (confirm('Czy potwierdzasz, że problem został rozwiązany?')) {
      AlarmService.resolveAlarm(alarm).then(function () {
        angular.forEach(vm.currentAlarms, function (item) {
          if (item.code === alarm.code) {
            item.triggered = false;
          }
        });
        alert("Zapisano, że problem został rozwiązany");
        $rootScope.$broadcast('refresh-station');
      });
    }
  };
}
