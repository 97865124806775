angular
  .module('app', ['ui.router', 'ui.bootstrap', 'ui.bootstrap.datetimepicker', 'angular-jwt', 'chart.js', 'btorfs.multiselect', 'angularMoment',
    'ngFileSaver'])
  .config(angularConfig)
  .run(angularRun);

/**
 * @ngInject
 * @param $rootScope
 */
function angularRun($rootScope) {
  // $rootScope.safeApply = function (fn) {
  //   var phase = $rootScope.$$phase;
  //   if (phase === '$apply' || phase === '$digest') {
  //     if (fn && (typeof(fn) === 'function')) {
  //       fn();
  //     }
  //   } else {
  //     this.$apply(fn);
  //   }
  // };
}

function angularConfig($urlRouterProvider, $httpProvider, $qProvider, jwtInterceptorProvider, jwtOptionsProvider, $templateRequestProvider, ChartJsProvider) {

  $templateRequestProvider.httpOptions({skipAuthorization: true});

  jwtInterceptorProvider.tokenGetter = function (jwtHelper, $http, UserService) {
    var token = localStorage.getItem('token');
    var refreshToken = localStorage.getItem('refreshToken');

    if (token !== null && jwtHelper.isTokenExpired(token)) {
      token = UserService.refreshToken(refreshToken);
    }

    return token;
  };

  jwtOptionsProvider.config({
    whiteListedDomains: ['localhost']
  });
  $httpProvider.interceptors.push('jwtInterceptor');

  ChartJsProvider.setOptions({
    animation: {
      duration: 0
    },
    global: {
      colors: [
        '#008ad1',
        '#803690',
        '#DC0008',
        '#46BFBD',
        '#FDB45C',
        '#949FB1',
        '#4D5360']
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 3,
        borderWidth: 0
      },
      line: {
        fill: false,
        showLine: true
      }
    }
  });
}
