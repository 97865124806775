(function () {
  'use strict';

  angular.module('app').run(initUserRun).service('UserService', UserService);

  /* @ngInject */
  function initUserRun($log, UserService) {
    UserService.current().then(function (user) {
      $log.info('user on start', user);
    }, function () {
      $log.info('user on start', null);
    });
  }

  /* @ngInject */
  function UserService($log, $q, HttpService) {
    var vm = this;
    var authenticatedDefer = $q.defer();

    vm.user = null;

    vm.userData = function () {
      return vm.user;
    };

    vm.setUser = function (user) {
      vm.user = user;
      authenticatedDefer.resolve(user);
    };

    vm.authenticated = function () {
      return authenticatedDefer.promise;
    };

    vm.login = function (login, password) {
      return HttpService.postJson('/api/user/login', {login: login, password: password}, true)
        .then(function (response) {
          $log.info('login', response);
          if (!response) {
            vm.setUser(null);
            return null;
          }
          localStorage.setItem('token', response.token);
          localStorage.setItem('refreshToken', response.refreshToken);
          return vm.current();
        });
    };

    vm.current = function () {
      authenticatedDefer = $q.defer();
      return HttpService.postJson('/api/user/current').then(function (response) {
        vm.user = response;
        vm.setUser(vm.user);
        return response;
      }, function () {
        vm.user = null;
        return null;
      });
    };

    vm.refreshToken = function (refreshToken) {
      return HttpService.postJson('/api/user/refreshToken', {refreshToken: refreshToken}, true)
        .then(function (response) {
          if (response === null) {
            return null;
          }
          localStorage.setItem('token', response.token);
          localStorage.setItem('refreshToken', response.refreshToken);
          return response.token;
        });
    };

    vm.logout = function (refreshToken) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
    };

    vm.users = function () {
      return HttpService.getJson('/api/user/list');
    };

    vm.saveUser = function (userData) {
      return HttpService.postJsonObject('/api/user/save', userData);
    };

    vm.saveProfile = function (userData) {
      return HttpService.postJsonObject('/api/user/saveProfile', userData);
    };

    vm.hasPerm = function (perm) {
      if (vm.userData() === null) {
        return false;
      }
      if (_.contains(vm.userData().role.permissions, perm)) {
        return true;
      }
      return false;
    };
  }
})();
