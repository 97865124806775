(function () {
  'use strict';

  angular.module('app').service('StationService', StationService);

  /* @ngInject */
  function StationService($log, HttpService) {
    var vm = this;

    vm.stationDetails = function (stationId) {
      return HttpService.postJson('/api/station/' + stationId);
    };

    vm.schemaAll = function () {
      return HttpService.postJson('/api/station/list');
    };

  }
})();
