(function () {
  'use strict';

  angular.module('app')
    .config(CurrentRoutes);

  /**
   * @ngInject
   * @param $stateProvider
   * @constructor
   */
  function CurrentRoutes($stateProvider) {
    $stateProvider
      .state('app.station.current', {
        url: '/current',
        component: 'current'
      });
  }
})();
