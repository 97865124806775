angular
  .module('app')
  .component('station', {
    templateUrl: 'app/modules/app/station/station.html',
    controller: StationCtrl
  });

function StationCtrl($state, $stateParams, $log, $scope, $rootScope, UserService, StationService, WebsocketService, moment) {
  var vm = this;

  vm.stationList = [];
  vm.schemasList = [];

  vm.stationId = $stateParams.stationId;

  UserService.authenticated().then(function (response) {
    vm.stationList = response.stations;
  });

  if (vm.stationId === 0 || vm.stationId === null || vm.stationId === '') {
    vm.stationId = 0;
    UserService.authenticated().then(function (user) {
      vm.stationId = UserService.userData().stations[0].id;
      if (vm.stationId !== null) {
        StationService.stationDetails(vm.stationId).then(function (response) {
          $state.go('app.station.schema', {stationId: vm.stationId, schemaId: response.tabs[0].id});
          vm.stationDetails = response;
          $rootScope.stationDetails = vm.stationDetails;
        });
      }
    });
  }
  $rootScope.stationId = vm.stationId;

  WebsocketService.changeStationId(vm.stationId);

  $rootScope.stationDetails = null;
  if (vm.stationId !== 0) {
    StationService.stationDetails(vm.stationId).then(function (response) {
      vm.stationDetails = response;
      $rootScope.stationDetails = vm.stationDetails;
    });
  }

  vm.lastUpdate = null;

  vm.receiveTimeUpdate = function (date) {
    vm.lastUpdate = moment(date).format('DD.MM.YYYY HH:mm:ss');
    $scope.$apply();
  };

  WebsocketService.setOnReceiveSensorTime(vm.receiveTimeUpdate);

  vm.isStationDetailsLoaded = function () {
    return Boolean($rootScope.stationDetails);
  };

  vm.getMenuLabel = function () {
    if ($state.current.name === 'app.station.schema') {
      if (vm.stationDetails) {
        var schemaId = Number($stateParams.schemaId);
        var schema = _.findWhere(vm.stationDetails.tabs, {id: schemaId});
        if (schema) {
          return schema.name;
        }
      }
      return 'Schemat';
    } else if ($state.current.name === 'app.station.current') {
      return 'Aktualne odczyty';
    } else if ($state.current.name === 'app.station.schema') {
      return 'Stany wodomierzy';
    } else if ($state.current.name === 'app.station.history') {
      return 'Historia';
    } else if ($state.current.name === 'app.station.config') {
      return 'Konfiguracja stacji';
    }
    return 'Menu';
  };

  vm.hasPerm = function (perm) {
    if (UserService.userData() === null) {
      return false;
    }
    if (_.contains(UserService.userData().role.permissions, perm)) {
      return true;
    }
    return false;
  };

  $scope.$on('refresh-station',function () {
    StationService.stationDetails(vm.stationId).then(function (response) {
      console.log("Station details refreshed");
      vm.stationDetails = response;
      $rootScope.stationDetails = vm.stationDetails;
    });
  });
}
