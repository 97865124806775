(function () {
  'use strict';

  angular.module('app')
    .config(UsersRoutes);

  /**
   * @ngInject
   * @param $stateProvider
   * @constructor
   */
  function UsersRoutes($stateProvider) {
    $stateProvider
      .state('app.settings.users', {
        url: '/users',
        component: 'users'
      });
  }
})();
