angular.module('app').service('WebsocketService', WebsocketService);

/* @ngInject */
function WebsocketService($q, $timeout, $log, HttpService) {
  var vm = this;

  var socket = {
    client: null,
    stomp: null,
    stationId: null,
    subscribedTopic: null,
    subscription: null
  };

  vm.reconnectTimeout = 5000;
  vm.socketUrl = HttpService.getApiMethod('/socket');
  vm.sensorsUpdateTopic = '/sensors/update/';
  vm.alarmsUpdateTopic = '/alarms/update/';

  vm.onReceiveSensor = null;
  vm.onReceiveSensorTime = null;

  vm.setOnReceiveSensor = function (onReceiveMethod) {
    vm.onReceiveSensor = onReceiveMethod;
  };

  vm.setOnReceiveSensorTime = function (onReceiveMethod) {
    vm.onReceiveSensorTime = onReceiveMethod;
  };

  vm.setOnReceiveAlarm = function (onReceiveMethod) {
    vm.onReceiveAlarm = onReceiveMethod;
  };


  function reconnect() {
    $log.warn('Reconnecting to socket in ' + vm.reconnectTimeout + ' ms');
    $timeout(function () {
      $log.warn('Reconnecting to socket now!');
      initialize();
    }, vm.reconnectTimeout);
  }

  function startListener() {
    if (socket.stationId !== null) {
      stopListeners();
      socket.sensorsUpdateTopicForStation = vm.sensorsUpdateTopic + String(socket.stationId);
      socket.sensorsSubscription = socket.stomp.subscribe(socket.sensorsUpdateTopicForStation, function (data) {
        if (vm.onReceiveSensor) {
          vm.onReceiveSensor(angular.fromJson(data.body));
        }
        if (vm.onReceiveSensorTime) {
          vm.onReceiveSensorTime(angular.fromJson(new Date()));
        }
      });

      socket.alarmsUpdateTopicForStation = vm.alarmsUpdateTopic + String(socket.stationId);
      socket.alarmsSubscription = socket.stomp.subscribe(socket.alarmsUpdateTopicForStation, function (data) {
        if (vm.onReceiveAlarm) {
          vm.onReceiveAlarm(angular.fromJson(data.body));
        }
      });
    }
  }

  function stopListeners() {
    if (socket.sensorsSubscription) {
      socket.stomp.unsubscribe(socket.sensorsSubscription.id);
      socket.sensorsSubscription = null;
    }
    if (socket.alarmsSubscription) {
      socket.stomp.unsubscribe(socket.alarmsSubscription.id);
      socket.alarmsSubscription = null;
    }
  }

  function initialize() {
    socket.client = new SockJS(vm.socketUrl);
    socket.stomp = Stomp.over(socket.client);
    socket.stomp.heartbeat.outgoing = 10000;
    socket.stomp.heartbeat.incoming = 10000;
    socket.stomp.connect({}, startListener, reconnect);
    socket.stomp.debug = null;
  }

  vm.changeStationId = function (stationId) {
    socket.stationId = stationId;
    if (!socket.stomp) {
      initialize();
    } else {
      startListener();
    }
  };

  // vm.chatEndpoint = '/app/chat';

  // vm.sendChatMessage = function (message) {
  //   var payload = angular.toJson({
  //     message: message
  //   });
  //
  //   socket.stomp.send(vm.chatEndpoint, {
  //     priority: 9
  //   }, payload);
  // };
}
