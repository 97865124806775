(function () {
  'use strict';

  angular.module('app').service('HttpService', HttpService);

  /* @ngInject */
  function HttpService($http, $location, $log) {
    var vm = this;

    var port = $location.port();
    if (port === 3000) {
      port = 8080;
    }
    vm.apiPrefix = 'http://' + $location.host() + ':' + port;

    vm.getApiMethod = function (method) {
      return vm.apiPrefix + method;
    };

    vm.get = function (method, skipAuthorization) {
      return $http({
        url: vm.getApiMethod(method),
        method: 'GET',
        skipAuthorization: skipAuthorization
      });
    };

    vm.getJson = function (method, skipAuthorization) {
      return vm.get(method, skipAuthorization)
        .then(function (response) {
          return angular.fromJson(response.data);
        }, function (reason) {
          $log.warn('GET error: ', reason);
          return null;
        });
    };

    vm.post = function (method, payload, skipAuthorization) {
      return $http({
        url: vm.getApiMethod(method),
        method: 'POST',
        data: payload,
        skipAuthorization: skipAuthorization
      });
    };

    vm.postBinary = function (method, payload, skipAuthorization) {
      return $http({
        url: vm.getApiMethod(method),
        method: 'POST',
        data: payload,
        responseType: 'arraybuffer',
        skipAuthorization: skipAuthorization
      });
    };

    vm.postJson = function (method, payload, skipAuthorization) {
      return vm.post(method, payload, skipAuthorization)
        .then(function (response) {
          return angular.fromJson(response.data);
        }, function (reason) {
          $log.warn('POST error: ', reason);
          return null;
        });
    };

    vm.postJsonObject = function (method, payload, skipAuthorization) {
      return vm.postJson(method, angular.toJson(payload), skipAuthorization);
    };
  }
})();
