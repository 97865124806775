(function () {
  'use strict';

  angular.module('app').service('AlarmService', AlarmService);

  /* @ngInject */
  function AlarmService($log, HttpService) {
    var vm = this;

    vm.saveAlarmConfig = function (criteria) {
      return HttpService.postJson('/api/alarm/save', criteria);
    };

    vm.resolveAlarm = function (criteria) {
      return HttpService.postJson('/api/alarm/resolve', criteria);
    };

    vm.saveAlarmNumberConfig = function (criteria) {
      return HttpService.postJson('/api/alarm/number/save', criteria);
    };

    vm.removeAlarmNumberConfig = function (criteria) {
      return HttpService.postJson('/api/alarm/number/remove', criteria);
    };

    vm.testAlarmNumber = function (criteria) {
      return HttpService.postJson('/api/alarm/number/test', criteria);
    };

  }
})();
