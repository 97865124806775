(function () {
  'use strict';

  angular.module('app')
    .config(TotalRoutes);

  /**
   * @ngInject
   * @param $stateProvider
   * @constructor
   */
  function TotalRoutes($stateProvider) {
    $stateProvider
      .state('app.station.total', {
        url: '/total',
        component: 'total'
      });
  }
})();
