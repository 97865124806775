(function () {
  'use strict';

  angular.module('app')
    .config(StationRoutes);

  /**
   * @ngInject
   * @param $stateProvider
   * @constructor
   */
  function StationRoutes($stateProvider) {
    $stateProvider
      .state('app.station', {
        url: '/station/{stationId:int}',
        component: 'station',
        abstract: true
      });
  }
})();
