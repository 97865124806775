angular
  .module('app')
  .component('history', {
    templateUrl: 'app/modules/app/station/history/history.html',
    controller: HistoryCtrl
  });

function HistoryCtrl($state, $stateParams, $log, $scope, $rootScope, moment, SensorService, WebsocketService, FileSaver, Blob) {
  var vm = this;

  vm.loading = false;

  vm.datepickerOptions = {
    maxDate: null,
    minDate: null,
    showWeeks: false
  };
  vm.timepickerOptions = {
    showMeridian: false
  };
  vm.buttonBar = {
    show: true,
    now: {
      show: true,
      text: 'Teraz'
    },
    today: {
      show: true,
      text: 'Dzisiaj'
    },
    clear: {
      show: false,
      text: 'Wyczyść'
    },
    date: {
      show: true,
      text: 'Data'
    },
    time: {
      show: true,
      text: 'Godzina'
    },
    close: {
      show: true,
      text: 'Zamknij'
    },
    cancel: {
      show: false,
      text: 'Anuluj'
    }
  };

  vm.multiselectLabels = {
    select: 'Wybierz',
    itemsSelected: 'wybrane',
    search: 'Wyszukaj...'
  };

  function sortByIdWithTwoNumbers(a, b) {
    var namea = a.id;
    var nameb = b.id;
    var lettersa = namea.replace(/[^\D]+/g, '');
    var lettersb = nameb.replace(/[^\D]+/g, '');
    var num1a = Number(namea.replace(/^[\D]+/, '').replace(/[\D]+[^\D]+(_T)?$/, ''));
    var num1b = Number(nameb.replace(/^[\D]+/, '').replace(/[\D]+[^\D]+(_T)?$/, ''));
    var num2a = Number(namea.replace(/^[\D]+[^\D]+[\D]+/, '').replace(/(_T)?$/, ''));
    var num2b = Number(nameb.replace(/^[\D]+[^\D]+[\D]+/, '').replace(/(_T)?$/, ''));

    if (lettersa !== lettersb) {
      return lettersa > lettersb ? 1 : (lettersa < lettersb ? -1 : 0);
    }
    if (num1a !== num1b) {
      return num1a > num1b ? 1 : (num1a < num1b ? -1 : 0);
    }
    return num2a > num2b ? 1 : (num2a < num2b ? -1 : 0);
  }

  vm.sensorsList = [];
  angular.forEach($rootScope.stationDetails.sensors, function (sensor) {
    vm.sensorsList.push({
      id: sensor.code,
      name: sensor.name
    });
  });
  vm.sensorsList = vm.sensorsList.sort(sortByIdWithTwoNumbers);
  vm.sensorsSelected = [];

  vm.dateFrom = moment().hour(0).minute(0).second(0).toDate();
  vm.dateTo = moment().hour(0).minute(0).second(0).add(1, 'days').toDate();
  vm.timeMode = 'period';
  vm.timePeriod = 1;
  vm.timeGrouping = null;
  vm.data = [];

  WebsocketService.setOnReceiveSensor(null);
  WebsocketService.setOnReceiveAlarm(null);

  function prepareCriteria(destination) {
    var sensorCodes = vm.sensorsSelected.map(function (sensorSelected) {
      return sensorSelected.id;
    });
    var criteria = {
      stationId: $rootScope.stationId,
      periodHours: (vm.timeMode === 'period' ? vm.timePeriod : null),
      dateFrom: (vm.timeMode === 'range' ? formatDate(vm.dateFrom) : null),
      dateTo: (vm.timeMode === 'range' ? formatDate(vm.dateTo) : null),
      sensorCodes: sensorCodes,
      destination: destination
    };
    return criteria;
  }

  function formatDate(date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  vm.loadTable = function () {
    vm.tableLoaded = false;
    vm.chartLoaded = false;

    vm.loading = true;
    SensorService.history(prepareCriteria('TABLE')).then(function (result) {
      vm.data = result.readings;
      vm.timeGrouping = result.timeGrouping;
      vm.tableLoaded = true;
      vm.chartLoaded = false;
      vm.loading = false;
    });
  };

  vm.loadChart = function () {
    vm.tableLoaded = false;
    vm.chartLoaded = false;
    var dataInTime = {};

    vm.loading = true;
    SensorService.history(prepareCriteria('CHART')).then(function (result) {
      vm.data = result.readings;
      vm.timeGrouping = result.timeGrouping;
      vm.chartLabels = [];
      vm.chartSeries = [];
      var values = [];
      angular.forEach(vm.data, function (row) {
        if (!dataInTime[row.time]) {
          dataInTime[row.time] = {};
        }
        dataInTime[row.time][row.sensorCode] = row.value;
        if (vm.chartSeries.indexOf(row.sensorCode) === -1) {
          vm.chartSeries.push(row.sensorCode);
        }
      });

      vm.chartData = [];

      for (var i = 0; i < vm.chartSeries.length; i++) {
        vm.chartData[i] = [];
      }
      angular.forEach(dataInTime, function (values, time) {
        vm.chartLabels.push(time);
        var valuesInTime = [];
        for (var i = 0; i < vm.chartSeries.length; i++) {
          vm.chartData[i].push(values[vm.chartSeries[i]]);
        }
      });
      vm.tableLoaded = false;
      vm.chartLoaded = true;
      vm.loading = false;
    });
  };

  // zależności między datami

  // var unwatchMinMaxValues = $scope.$watch(function() {
  //   return [that.picker4, that.picker5, that.picker10, that.picker11];
  // }, function() {
  //   // min max dates
  //   that.picker4.datepickerOptions.maxDate = that.picker5.date;
  //   that.picker5.datepickerOptions.minDate = that.picker4.date;
  //
  //   if (that.picker4.date && that.picker5.date) {
  //     var diff = that.picker4.date.getTime() - that.picker5.date.getTime();
  //     that.dayRange = Math.round(Math.abs(diff/(1000*60*60*24)))
  //   } else {
  //     that.dayRange = 'n/a';
  //   }
  //
  //   // min max times
  //   that.picker10.timepickerOptions.max = that.picker11.date;
  //   that.picker11.timepickerOptions.min = that.picker10.date;
  // }, true);
  //
  //
  // // destroy watcher
  // $scope.$on('$destroy', function() {
  //   unwatchMinMaxValues();
  // });

  // wykresy
  vm.chartLabels = [];
  vm.chartSeries = [];
  vm.chartData = [];

  vm.chartClicked = function (points, evt) {
    $log.info('Clicked', points, evt);
  };
  vm.datasetOverride = [
    // {
    //   borderColor: 'red'
    // }
    //   {
    //   yAxisID: 'y-axis',
    // },
    //   {
    //     yAxisID: 'y-axis-2',
    //   }
  ];
  vm.options = {
    scales: {
      // yAxes: [
      //   {
      //     id: 'y-axis',
      //     type: 'linear',
      //     display: true,
      //     position: 'left',
      //     ticks: {
      //       beginAtZero: true
      //     }
      //   },
      //   {
      //     id: 'y-axis-2',
      //     type: 'linear',
      //     display: true,
      //     position: 'left',
      //     ticks: {
      //       beginAtZero: true
      //     }
      //   }
      // ]
    },
    legend: {
      display: true
    }
  };

  vm.export = function () {
    vm.loading = true;
    var criteria = prepareCriteria('EXPORT');
    SensorService.export(criteria).then(function (result) {
      var data = new Blob([result.data], {type: 'application/vnd.ms-excel'});
      FileSaver.saveAs(data, 'export.xls');
      vm.loading = false;
    });
  };

  vm.getGroupingSettings = function () {
    if (vm.timeGrouping <= 10) {
      return '10 sekund';
    } else if (vm.timeGrouping <= 60) {
      return '1 minuty';
    } else if (vm.timeGrouping <= 600) {
      return '10 minut';
    } else if (vm.timeGrouping <= 3600) {
      return '1 godziny';
    }
    return '1 dnia';
  };
}
