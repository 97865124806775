angular
  .module('app')
  .component('login', {
    templateUrl: 'app/modules/login/login.html',
    controller: LoginCtrl
  });

function LoginCtrl($log, $state, UserService) {
  var vm = this;

  vm.invalidLoginMessage = null;
  vm.userData = {
    login: '',
    password: ''
  };

  vm.errorMessages = {
    invalidData: 'Błędny login lub hasło',
    noStations: 'Użytkownik nie ma udostępnionej żadnej stacji'
  };

  vm.getModeratorEmail = function() {
    // return unescape('%6b%6f%6e%74%61%6b%74%40%77%69%7a%75%61%6c%69%7a%61%63%6a%61%2e%6e%65%74');
    return unescape('%77%69%7a%75%61%6c%69%7a%61%63%6a%61%40%70%72%6f%74%79%70%65%72%2e%6e%65%74');
  };

  vm.login = function () {
    UserService.login(vm.userData.login, vm.userData.password).then(function (response) {
      if (!response) {
        vm.invalidLoginMessage = vm.errorMessages.invalidData;
      } else {
        if (response.stations.length === 0) {
          vm.invalidLoginMessage = vm.errorMessages.noStations;
          return;
        }
        var stationId = response.stations[0].id;
        $state.go('app.station.schema', {stationId: stationId, schemaId: 0});
      }
    });
  };

}
