(function () {
  'use strict';

  angular.module('app')
    .config(ConfigRoutes);

  /**
   * @ngInject
   * @param $stateProvider
   * @constructor
   */
  function ConfigRoutes($stateProvider) {
    $stateProvider
      .state('app.station.config', {
        url: '/config',
        component: 'config'
      });
  }
})();
