(function () {
  'use strict';

  angular.module('app')
    .config(AppRoutes);

  /**
   * @ngInject
   * @param $stateProvider
   * @constructor
   */
  function AppRoutes($stateProvider) {
    $stateProvider
      .state('app', {
        url: '/app',
        component: 'app',
        abstract: true
      });
  }
})();
