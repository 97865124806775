angular
  .module('app')
  .component('config', {
    templateUrl: 'app/modules/app/station/config/config.html',
    controller: ConfigCtrl
  });

function ConfigCtrl($scope, $rootScope, $state, $stateParams, $log, $interval, SensorService, WebsocketService, AlarmService) {
  var vm = this;

  vm.stationId = $stateParams.stationId;
  vm.sensors = angular.copy($rootScope.stationDetails.sensors);
  vm.sensors = vm.sensors.sort(sortByNameWithTwoNumbers);
  vm.sensorsOther = [];
  vm.alarms = angular.copy($rootScope.stationDetails.alarms);
  vm.alarmNumbers = angular.copy($rootScope.stationDetails.alarmNumbers);

  angular.forEach(vm.sensors, function (sensor) {
    if (sensor.sensorType !== 'WATER_METER' && sensor.sensorType !== 'LINEAR') {
      vm.sensorsOther.push(sensor);
    }
  });

  function sortByNameWithTwoNumbers(a, b) {
    var namea = a.code;
    var nameb = b.code;
    var lettersa = namea.replace(/[^\D]+/g, '');
    var lettersb = nameb.replace(/[^\D]+/g, '');
    var num1a = Number(namea.replace(/^[\D]+/, '').replace(/[\D]+[^\D]+(_T)?$/, ''));
    var num1b = Number(nameb.replace(/^[\D]+/, '').replace(/[\D]+[^\D]+(_T)?$/, ''));
    var num2a = Number(namea.replace(/^[\D]+[^\D]+[\D]+/, '').replace(/(_T)?$/, ''));
    var num2b = Number(nameb.replace(/^[\D]+[^\D]+[\D]+/, '').replace(/(_T)?$/, ''));

    if (lettersa !== lettersb) {
      return lettersa > lettersb ? 1 : (lettersa < lettersb ? -1 : 0);
    }
    if (num1a !== num1b) {
      return num1a > num1b ? 1 : (num1a < num1b ? -1 : 0);
    }
    return num2a > num2b ? 1 : (num2a < num2b ? -1 : 0);
  }

  vm.refreshReadings = function (result) {
    angular.forEach(result, function (row) {
      angular.forEach(vm.sensors, function (displayed) {
        if (displayed.code === row.sensorCode) {
          displayed.value = row.value.toFixed(2);
        }
        if (displayed.code + '_T' === row.sensorCode) {
          if (row.value) {
            displayed.totalValue = row.value.toFixed(2);
          }
          if (row.realValue) {
            displayed.realTotalValue = row.realValue.toFixed(2);
          }
          displayed.totalValueExists = true;
        }
      });
    });
    $scope.$apply();
  };

  WebsocketService.setOnReceiveSensor(vm.refreshReadings);
  WebsocketService.setOnReceiveAlarm(null);

  vm.saveSensor = function (sensor) {
    if (sensor.afactor instanceof String) {
      sensor.afactor = sensor.afactor.replace(/,/g, '.');
    }
    if (sensor.bfactor instanceof String) {
      sensor.bfactor = sensor.bfactor.replace(/,/g, '.');
    }
    SensorService.saveSensorConfig(sensor).then(function () {
      alert('Dane zapisane');
      $rootScope.$broadcast('refresh-station');
    });
  };

  vm.saveAlarm = function (alarm) {
    AlarmService.saveAlarmConfig(alarm).then(function () {
      alert('Dane zapisane');
      $rootScope.$broadcast('refresh-station');
    });
  };

  vm.saveAlarmNumber = function (alarmNumber) {
    alarmNumber.station = { id: vm.stationId };
    AlarmService.saveAlarmNumberConfig(alarmNumber).then(function () {
      alert('Dane zapisane');
      $rootScope.$broadcast('refresh-station');
    });
  };

  vm.removeAlarmNumber = function (alarmNumber) {
    if (confirm('Czy napewno chcesz usunąć numer?')) {
      if(!alarmNumber.id) {
        vm.removeAlarmNumberFromList(alarmNumber);
        alert('Wpis usunięty');
      } else {
        AlarmService.removeAlarmNumberConfig(alarmNumber).then(function () {
          vm.removeAlarmNumberFromList(alarmNumber);
          alert('Wpis usunięty');
          $rootScope.$broadcast('refresh-station');
        });
      }
    }
  };

  vm.removeAlarmNumberFromList = function(alarmNumber) {
    $rootScope.stationDetails.alarmNumbers.splice(
      $rootScope.stationDetails.alarmNumbers.indexOf(alarmNumber),
      1
    );
  };

  vm.testAlarmNumber = function(alarmNumber) {
    var data = angular.copy(alarmNumber);
    data.station = {id:$rootScope.stationDetails.id};
    AlarmService.testAlarmNumber(data).then(function (data) {
      if (data === true) {
        alert("Wysłano wiadomość testową");
      } else {
        alert("Błąd wysyłania wiadomości testowej");
      }

      console.log(data);
    }, function(data) {
      alert("Błąd wysyłania wiadomości");
      console.log("error"+data);
    });
  };

  vm.setTotal = function (sensor) {
    if (!sensor.totalValueExpected) {
      sensor.totalValueExpected = 0;
    }
    if (sensor.realTotalValue && sensor.valuePerImpuls) {
      var realTotalValueWithOverflow = Number(sensor.realTotalValue);
      if (sensor.overflowCounter && sensor.overflowValue) {
        var overflowTotal = sensor.overflowCounter * sensor.overflowValue;
        realTotalValueWithOverflow = realTotalValueWithOverflow + (overflowTotal);
      }
      sensor.totalOffset = (sensor.totalValueExpected - (realTotalValueWithOverflow * sensor.valuePerImpuls).toFixed(2)).toFixed(2);
    }
    vm.saveSensor(sensor);
  };

  vm.hasSection = function (sectionCode) {
    return _.some(vm.sensors, {section: sectionCode});
  };

  vm.hasSensorTypeInSection = function (sensorType, sectionCode) {
    return _.some(vm.sensors, {sensorType: sensorType, section: sectionCode});
  };

  vm.areAlarmsEnabled = function () {
    return $rootScope.stationDetails.alarmsEnabled;
  };

  vm.addAlarmNumber = function () {
    vm.alarmNumbers.push({
      name: '',
      message: '',
      phoneNumber: '',
      enabled: true
    });
  };
}
